<template>
  <Card title="sign_up">
    <v-form ref="form">
      <v-row dense>
        <v-col>
          <v-text-field
            v-model="credentials.email"
            outlined
            dense
            background-color="white"
            color="var(--color-accent)"
            :placeholder="$t('message.email')"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-text-field
            v-model="credentials.password"
            :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
            outlined
            dense
            :type="showPassword ? 'text' : 'password'"
            background-color="white"
            color="var(--color-accent)"
            :placeholder="$t('message.password')"
            @click:append="showPassword = !showPassword"
            :rules="[rules.required, rules.minimumLength]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-text-field
            v-model="confirmPassword"
            :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
            outlined
            dense
            :type="showPassword ? 'text' : 'password'"
            background-color="white"
            color="var(--color-accent)"
            :placeholder="$t('message.confirm_password')"
            @click:append="showPassword = !showPassword"
            :rules="[rules.required, matchPassword]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-4 mb-2" justify="center">
        <v-btn rounded @click="signUp">{{ $t("button.sign_up") }} </v-btn>
      </v-row>
    </v-form>
  </Card>
</template>

<script>
import { mapActions } from "vuex";
import Card from "@/components/Card.vue";

export default {
  name: "SignUp",
  components: {
    Card,
  },
  data() {
    return {
      credentials: { email: null, password: null },
      showPassword: false,
      confirmPassword: null,
      rules: {
        required: (v) => !!v || this.$t("message.required"),
        minimumLength: (v) =>
          (!!v && v.length > 5) || this.$t("message.too_short"),
      },
    };
  },
  methods: {
    signUp() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.signUpWithEmailAndPassword(this.credentials);
    },
    ...mapActions(["signUpWithEmailAndPassword"]),
  },
  computed: {
    matchPassword() {
      return (confirmPassword) =>
        confirmPassword == this.credentials.password ||
        this.$t("message.passwords_should_match");
    },
  },
};
</script>